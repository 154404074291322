<template>
  <div>
    <el-form label-width="80px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       clearable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add">新增</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <RelevantForm ref="RelevantFormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import RelevantForm from './components/relevantForm.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'question_category_id', align: 'center', label: '题目类别编号' },
        { prop: 'question_category_name', align: 'center', label: '题目类别名称' },
        { prop: 'question_category_desc', align: 'center', label: '题目类别描述' },
        // { prop: 'question_category_parent_id', align: 'center', label: '题目类别父类编号', width: ''},
        { prop: 'subject_name', align: 'center', label: '科目名称' },
        { prop: 'districts', align: 'center', label: '试卷地区', width: '', isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '地区关联知识点', type: 'relation', event: 'editKnowledge' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      form: {
        subject_id: ''
      },
      tableData: [],
      subjectList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'slcz_paperCategory_admin',
    }
  },
  components: {
    Form, RelevantForm
  },
  mounted () {
    this.setTablesColums()
    this.getSubjectList()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getClass (prop, value) {
      if (prop == 'districts') {
        return ''
      }
    },
    getValue (prop, value) {
      if (prop == 'districts') {
        var str = ''
        value.forEach(item => {
          str += item.paper_district_name + ','
        });
        return str
      }
    },
    initData (page, limit) {
      let params = {
        page,
        limit,
        subject_id: this.form.subject_id
      }
      this.$http({
        url: '/api/v2/paper/question_category',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    editRow (row) {
      let form = {
        question_category_id: row.question_category_id,
        subject_id: row.subject_id,
        paper_districts: row.districts.map(item => {
          return item.paper_district_id
        }),
        question_category_name: row.question_category_name,
        question_category_desc: row.question_category_desc,
        isCover: row.isCover || 0,
        knowledge_data: []
      }
      if (row.knowledge_data) {
        form.knowledge_data = JSON.parse(row.knowledge_data)
      }

      this.$refs.FormRef.text = '修改'
      // this.$refs.FormRef.knowledge_ids = form.knowledge_data
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getknowledgeList()
      this.$refs.FormRef.dialogVisible = true
    },
    async editKnowledge (row) {
      if (!row.districts.length) {
        this.$message.warning('该类别未关联地区')
        return
      }
      this.$refs.RelevantFormRef.getknowledgeList(row.subject_id)
      this.$refs.RelevantFormRef.question_category_id = row.question_category_id
      this.$refs.RelevantFormRef.getInitData()
      this.$refs.RelevantFormRef.dialogVisible = true
    },
    add () {
      let form = {
        subject_id: '',
        paper_districts: [],
        question_category_name: '',
        question_category_desc: ''
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((() => {
        this.$http({
          url: '/api/v2/paper/question_category_del',
          method: 'POST',
          data: {
            question_category_id: row.question_category_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      }))
    }
  }
}
</script>

<style lang="scss">
</style>