<template>
  <div>
    <el-dialog title="关联知识点"
               v-dialogDrag
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form v-if="dialogVisible"
               ref="formRef"
               label-width="90px"
               class="list_block">
        <div v-for="item,index in formList"
             :key="index"
             class="item_block">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="试卷地区"
                            prop="question_category_name">
                <el-input v-model="item.district_name"
                          style="width:100%"
                          readonly />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="关联知识点">
                <el-cascader v-model="item.knowledge_data"
                             ref="cascaderRef"
                             :options="knowledgetList"
                             :props="props"
                             :key="randomId+''+index"
                             placeholder="选择"
                             collapse-tags
                             style="width:100%"
                             filterable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="关联题号">
                <el-input placeholder="请输入如：1,2,3"
                          v-model="item.number_data"
                          style="width:100%" />
              </el-form-item>
            </el-col>
          </el-row>

        </div>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getParentIds, getFlatlist } from '@/utils/common.js'

export default {
  data () {
    return {
      question_category_id: '',
      knowledgetList: [],
      parentIdList: [],
      flatknowledgetList: [],
      dialogVisible: false,
      props: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc'
        // emitPath: false,// 只返回该节点的值
      },
      randomId: Math.random(),
      formList: [

      ],
    }
  },
  mounted () {

  },
  methods: {
    getSelectedID (arr1) {
      let arr = JSON.parse(JSON.stringify(arr1))
      let idArr = []
      let parentId = []
      arr.forEach(item => {
        idArr.push(getParentIds(item, this.flatknowledgetList, 'knowledge_parent_id', 'knowledge_id'))
      })

      for (let i = 0; i < idArr.length; i++) {
        // 因为arr中含有父级元素id，不止子元素
        // 删除重复的父级数组,确保每个数组中每个元素的最后一个元素是级联的顶级（不含子元素）
        if (this.parentIdList.indexOf(idArr[i][idArr[i].length - 1]) != -1) {
          idArr.splice(i, 1)
          i--
        }
      }
      console.log(idArr, 'aa')
      return idArr
    },
    // 将二维id数组扁平化，去重复
    flatID (ids) {
      let id = []
      for (let i = 0, len = ids.length; i < len; i++) {
        id.push(...ids[i])
      }
      return [...new Set(id)]
    },

    getInitData () {
      // 获取题目类别地区的知识点与题号数据
      this.$http({
        url: "/api/v2/paper/question_category_correlation_detail",
        method: 'get',
        params: {
          question_category_id: this.question_category_id
        }
      }).then(res => {
        this.formList = res.data.list

        this.formList.map(item => {
          item.number_data = item.number_data.join(',')
        })
        // 若是知识点数据已加载完成，则在这里转换格式，否则在getknowledgeList中转换
        if (this.flatknowledgetList.length) {
          this.formList.map(item => {
            // 将知识点字段转为级联选择器需要的格式
            item.knowledge_data = this.getSelectedID(item.knowledge_data)
          })
        }
      })
    },

    getknowledgeList (subjectId) {
      this.$http({
        url: '/api/v2/knowledge/lst',
        method: 'get',
        params: {
          subject_id: subjectId
        }
      }).then(res => {
        this.knowledgetList = res.data.list
        this.flatknowledgetList = getFlatlist(res.data.list, 'child')
        this.parentIdList = []
        this.$nextTick(() => {
          for (let i = 0; i < this.flatknowledgetList.length; i++) {
            // 获取含子元素的知识点id集合,
            if (this.flatknowledgetList[i].knowledge_parent_id) {
              this.parentIdList.push(this.flatknowledgetList[i].knowledge_parent_id)
            }
          }
          if (this.formList.length) {
            this.formList.map(item => {
              // 将formList知识点字段转为级联选择器需要的格式
              item.knowledge_data = this.getSelectedID(item.knowledge_data)
            })
          }
          this.$nextTick(() => {
            // 级联选择器优化
            const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
            Array.from($el).map((item) => item.removeAttribute('aria-owns'));
          });
        })
      })
    },
    save () {
      let correlation = []
      for (let i = 0; i < this.formList.length; i++) {
        // 替换中文逗号，去掉空格
        let num = this.formList[i].number_data.replace(/，/ig, ',').replace(/\s*/g, "")
        let data = {
          knowledge_id: this.flatID(this.formList[i].knowledge_data),
          number: !!num ? num.split(',') : []
        }
        correlation.push({
          district_id: this.formList[i].district_id,
          data
        })
      }
      this.$http({
        url: "/api/v2/paper/question_category_correlation",
        method: 'post',
        data: {
          question_category_id: this.question_category_id,
          correlation
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.handleClose()
      })
    },


    handleClose () {
      this.dialogVisible = false
      this.formList = []
      this.knowledgetList = []
      this.parentIdList = []
      this.flatknowledgetList = []
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}
.item_block {
  margin-bottom: 20px;
  border-bottom: 1px dashed #ccc;
}
.list_block {
  max-height: 600px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 15px;

  .item_block:last-of-type {
    border-bottom: 0;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgb(202, 202, 202);
  border-radius: 50px;
}
</style>