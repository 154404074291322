<template>
  <div>
    <el-dialog :title="text + '题目类别'"
               v-dialogDrag
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="520px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               style="width:98%"
               label-width="120px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="类别名称"
                          prop="question_category_name">
              <el-input placeholder=" 输入如：阅读理解..."
                        v-model="form.question_category_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="类别描述">
              <el-input placeholder="请输入"
                        type="textarea"
                        :rows="4"
                        v-model="form.question_category_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="所属科目"
                          prop="subject_id">
              <el-select style="width:100%"
                         @change="changeSubject"
                         v-model="form.subject_id"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in $parent.subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="试卷地区">
              <el-select style="width:100%"
                         v-model="form.paper_districts"
                         clearable
                         filterable
                         multiple
                         placeholder="请选择">
                <el-option v-for="item in disList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="关联知识点">
              <el-cascader v-model="knowledge_ids"
                           ref="cascaderRef"
                           :options="knowledgetList"
                           :props="props"
                           :key='randomId'
                           placeholder="请选择"
                           collapse-tags
                           style="width:100%"
                           filterable></el-cascader>
            </el-form-item>

          </el-col>
          <el-col :span="24">
            <el-form-item label="覆盖地区知识点">
              <el-switch v-model="form.isCover"
                         :active-value="1"
                         :inactive-value="0"
                         active-color="#409EFF"
                         inactive-color="#ccc"
                         active-text="是"
                         inactive-text="否">
              </el-switch>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getParentIds, getFlatlist } from '@/utils/common.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        question_category_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      text: '',
      randomId: Math.random(),
      knowledgetList: [],
      flatknowledgetList: [],
      parentIdList: [],
      knowledge_ids: [],
      props: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
        // emitPath: false,// 只返回该节点的值
      }
    }
  },

  mounted () {
    this.getDisList()
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
      this.knowledge_ids = []
    },
    changeSubject () {
      this.form.knowledge_data = []
      this.knowledge_ids = []
      this.getknowledgeList()
    },
    // 将返回数据转为级联选择器所需格式，用于回显
    getSelectedID (arr1) {
      let arr = JSON.parse(JSON.stringify(arr1))
      let idArr = []
      arr.forEach(item => {
        idArr.push(getParentIds(item, this.flatknowledgetList, 'knowledge_parent_id', 'knowledge_id'))
      })

      for (let i = 0; i < idArr.length; i++) {
        // 因为arr中含有父级元素id，不止子元素
        // 删除重复的父级数组,确保每个数组中每个元素的最后一个元素是级联的顶级
        if (this.parentIdList.indexOf(idArr[i][idArr[i].length - 1]) != -1) {
          idArr.splice(i, 1)
          i--
        }
      }
      return idArr
    },
    // 将二维id数组扁平化，
    flatID (ids) {
      let id = []
      for (let i = 0, len = ids.length; i < len; i++) {
        id.push(...ids[i])
      }
      return [...new Set(id)]
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.form.knowledge_data = this.flatID(this.knowledge_ids)
        let form = JSON.parse(JSON.stringify(this.form))
        form.paper_districts = form.paper_districts.join(',')
        this.$http({
          url: '/api/v2/paper/question_category_add',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },
    getDisList () {
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.disList = res.data.list
      })
    },
    getknowledgeList () {
      this.$http({
        url: '/api/v2/knowledge/lst',
        method: 'get',
        params: {
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.knowledgetList = res.data.list
        this.flatknowledgetList = getFlatlist(res.data.list, 'child')
        this.parentIdList = []
        this.$nextTick(() => {
          for (let i = 0; i < this.flatknowledgetList.length; i++) {
            // 获取含子元素的知识点id,
            if (this.flatknowledgetList[i].knowledge_parent_id) {
              this.parentIdList.push(this.flatknowledgetList[i].knowledge_parent_id)
            }
          }
          if (this.form.knowledge_data && this.form.knowledge_data.length) {
            this.knowledge_ids = this.getSelectedID(this.form.knowledge_data)
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>